export default function () {
  const isManager = useState<boolean>('is_manager', () => false)

  async function check() {
    const { data } = await useFetch('/api/manager/check')
    isManager.value = data.value || false
  }

  return { isManager, check }
}
